import {
  VuexModule,
  Module,
  Mutation,
  getModule,
  Action
} from 'vuex-module-decorators'
import store from '@/store'
import {
  getToken,
  setToken,
  removeToken,
  getUserInfo,
  setUserInfo,
  removeUserInfo,
  // getLocalVipLevels,
  // setVipLevels,
  // getLocalProductList,
  // setProductList
} from '@/utils/cookies'
import { IVipModel, IRoleModel, IBarsModel, IRolesModel } from '@/data/commonModel'
// import { getVipLevel } from '@/remote/common'
import { getProductList } from '@/remote/product'
import { inActivityBars } from '@/remote/bar'

export interface IBarModel {
  depositPerPerson: number
}

export interface IUserState {
  accessToken: string
  username: string
  password: string
  id: string
  sessionStart: string
  email: string
  roles: IRolesModel[]
  // vipLevels: IVipModel[]
  productList: object[]
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUserState {
  public accessToken = getToken() || ''
  public username = ''
  public password = ''
  public email = ''
  public id = ''
  public sessionStart = ''
  // public bar = { depositPerPerson: 0 }
  public roles: IRolesModel[] = []
  public wineryId = ''
  // public vipLevels = []
  public productList = []
  public isMain: boolean = false
  public curBar!: IBarsModel
  public wineries: IBarsModel[] = []
  public barChangeCall: Function[] = []
  @Mutation
  public SET_TOKEN(token: string) {
    this.accessToken = token
  }

  @Mutation
  public SET_TOKEN_NEW(token: string) {
    this.accessToken = `Bearer ${token}`
    // setToken(`Bearer ${token}`)
  }

  @Mutation
  public RESET_TOKEN() {
    removeToken()
    this.accessToken = ''
    location.reload()
  }

  @Mutation
  public RECORD_USERINFO_FOR_TEST(userInfo: any) {
    // Warning 在记录时先不录入roles数据, 并将roles数据清空
    // console.log("RECORD_USERINFO")
    // console.log(userInfo)
    this.roles = []
    let { token, email, name, id, wineries } = userInfo
    this.email = email
    this.username = name
    // this.roles=roles
    this.id = id
    this.wineries = wineries
    // userInfo.token=
    // console.log("token")
    // console.log(token)
    this.wineryId = this.wineries[0].id
    this.isMain = this.wineries[0].isMain
    this.accessToken = `Bearer ${token}`
    // console.log(this.accessToken)
    // console.log(userInfo)
    // setToken(`Bearer ${token}`)
    // setUserInfo(JSON.stringify(userInfo))
  }

  @Mutation
  public RECORD_USERINFO(userInfo: any) {
    // Warning 在记录时先不录入roles数据, 并将roles数据清空
    // console.log("RECORD_USERINFO")
    // console.log(userInfo)
    this.roles = []
    let { email, name, id, wineries, sessionStart } = userInfo
    this.email = email
    this.username = name
    // this.roles=roles
    this.id = id
    // the session start datetime
    this.sessionStart = sessionStart
    this.wineries = wineries
    // userInfo.token=
    // console.log(userInfo)
    this.wineryId = this.wineries[0].id
    this.isMain = this.wineries[0].isMain
    // this.accessToken = `Bearer ${token}`

    // setToken(`Bearer ${token}`)
    // setUserInfo(JSON.stringify(userInfo))
  }

  @Mutation
  public INIT_USERINFO() {
    // console.log("INIT_USERINFO")
    const res = getUserInfo()
    if (res) {
      const userInfo = JSON.parse(res)
      // console.log(userInfo)
      let { email, name, id, wineries } = userInfo
      this.email = email
      this.username = name
      this.id = id

      //todo test
      // wineries[0].roles[0].label='Content Agency'

      this.wineries = wineries
      // console.log("new")
      // console.log(this.wineryId)
      this.wineryId = this.wineries[0].id
      this.isMain = this.wineries[0].isMain
      // console.log(this.curBar)
      // this.accessToken = `Bearer ${token}`
      // if (roles) {
      //   this.roles = roles
      // }
      // Waring 测试数据
      // this.roles = ['admin']
      // setToken(`Bearer ${token}`)
    }
  }

  @Mutation
  public CLEAR_USERINFO() {
    this.email = ''
    this.username = ''
    this.id = ''
    this.accessToken = ''
    this.roles = []
    this.barChangeCall = []
    // this.bar = { depositPerPerson: 0 }
    removeToken()
    removeUserInfo()
  }
  @Mutation
  public addBarChangeCallBack(cb: Function) {
    this.barChangeCall = []
    this.barChangeCall.push(cb)

  }

  @Mutation
  public SET_BAR_INDEX(bar: string) {
    this.wineries.forEach((element, index) => {
      if (element.id === bar) {
        this.wineryId = element.id
        this.curBar = element
        this.isMain = element.isMain
      }
    })
    // console.log(this.barChangeCall)
    if (this.barChangeCall && this.barChangeCall.length > 0) {
      this.barChangeCall.forEach((element: Function) => {
        element()
      })
    }
  }

  // @Mutation
  // public INIT_COMMONDATA() {
  // const vipLevels = getLocalVipLevels()
  // if (vipLevels) {
  //   this.vipLevels = JSON.parse(vipLevels)
  // }
  // const productList = getLocalProductList()
  // if (productList) {
  //   this.productList = JSON.parse(productList)
  // }
  // }

  // @Mutation
  // SET_VIPLEVELS(data: any) {
  //   // this.vipLevels = data
  //   // setVipLevels(data)
  // }
  @Mutation
  SET_BARS(data: any) {
    this.wineries = data
  }
  // @Mutation
  // SET_PRODUCTLIST(data: any) {
  //   this.productList = data
  //   setProductList(data)
  // }

  // @Action
  // public async FetchVipLevels() {
  //   try {
  //     const res = await getVipLevel()
  //     if (res && res.data && res.data.data) {
  //       const { data } = res.data
  //       this.SET_VIPLEVELS(data)
  //     }
  //   } catch (error) {
  //     console.log(error)
  //     console.log('getVipLevel 出错了')
  //   }
  // }

  // @Action
  // public async FetchProductList() {
  //   try {
  //     const res = await getProductList()
  //     if (res && res.data && res.data.data) {
  //       const { data } = res.data
  //       this.SET_PRODUCTLIST(data)
  //     }
  //   } catch {
  //     console.log('getProductList 出错了')
  //   }
  // }

  @Action
  public async FetActiveAllBars() {
    try {
      const res = await inActivityBars()
      if (res && res.data && res.data.data) {
        const { data } = res.data
        // console.log(data)
        // console.log( this.wineries)
        this.SET_BARS(data)
      }
    } catch (error) {
      // console.log(error)
      // console.log('FetActiveAllBars 出错了')
    }
  }
}

export const UserModule = getModule(User)
