import qs from "qs";
import http from "./interface";
import {
  IBookingReqModel,
  IBookingListQuery,
  ISetArriveReqModel,
  IBookingProductSelectionReqModel,
  IProduceReqModel
} from "@/pages/booking/data/bookingModel";
import { UserModule } from "@/store/modules/user";

// 获取各个状态的预约数量
export const getReservationCountByStatus = () => {
  return http.get(`/api/v3/wineries/${UserModule.wineryId}/bookings/count-stats`);
};

// 店员手动到店
export const setArrived = (bookingId: string, arrived: ISetArriveReqModel) => {
  return http.put(
    `/api/v3/wineries/${UserModule.wineryId}/bookings/${bookingId}/redeem`,
    arrived
  );
};

// 店员手动未到店
export const setUnattended = (bookingId: string) => {
  const url = `/api/v3/wineries/${UserModule.wineryId}/bookings/${bookingId}/unattended`;
  return http.patch(url);
};

// 取消预约
export const cancelReservation = (bookingId: string) => {
  const url = `/api/v3/wineries/${UserModule.wineryId}/bookings/${bookingId}/force-refund`;
  return http.patch(url);
};

// 同意退款
export const acceptRefund = (requestId: string) => {
  const url = `/api/v3/wineries/${UserModule.wineryId}/refund/requests/${requestId}/decision`;
  return http.post(url, { action: 'approve' });
};

// 退款
export const refund = (scope: string,bookingId: string, products: IProduceReqModel[]) => {
  const url = `/api/v3/wineries/${UserModule.wineryId}/refund`;
  return http.post(url, { scope,bookingId,products:products.length?products:null });
};

// 拒绝退款
export const rejectRefund = (requestId: string) => {
  const url = `/api/v3/wineries/${UserModule.wineryId}/refund/requests/${requestId}/decision`;
  return http.post(url, { action: 'reject' });
};

// 拒绝预约
export const rejectReservation = (bookingId: string) => {
  const url = `/api/v3/wineries/${UserModule.wineryId}/bookings/${bookingId}/reject`;
  return http.patch(url);
};

// 接受预约
export const acceptReservation = (bookingId: string) => {
  return http.patch(
    `/api/v3/wineries/` +
      `${UserModule.wineryId}` +
      `/bookings/${bookingId}/accept`
  );
};

export const getReservationRecords = (data: IBookingListQuery) => {
  const { page, size, searchText, status, bookingTime, guestNumber, start, to } =
    data;
    let range
    if(start){
      return http.get(
        `/api/v3/wineries/${UserModule.wineryId}/bookings?pageIndex=${page-1}&pageSize=${size}&status=${status}&bookingTime=${bookingTime}&searchText=${searchText}&dateRange=${start}-${to}&guestNumber=${guestNumber}&isRefunding=false`
      );
    }
  return http.get(
    `/api/v3/wineries/${UserModule.wineryId}/bookings?pageIndex=${page-1}&pageSize=${size}&status=${status}&bookingTime=${bookingTime}&searchText=${searchText}&guestNumber=${guestNumber}&isRefunding=false`
  );
};

export const getRefundRequests = (data: IBookingListQuery) => {
  const { page, size, searchText, status, bookingTime, guestNumber, start, to } =
    data;
    if(start){
      return http.get(
        `/api/v3/wineries/${UserModule.wineryId}/bookings?pageIndex=${page-1}&pageSize=${size}&bookingTime=${bookingTime}&searchText=${searchText}&dateRange=${start}-${to}&guestNumber=${guestNumber}&isRefunding=true`
      );
    }
    return http.get(
      `/api/v3/wineries/${UserModule.wineryId}/bookings?pageIndex=${page-1}&pageSize=${size}&bookingTime=${bookingTime}&searchText=${searchText}&guestNumber=${guestNumber}&isRefunding=true`
    );
};

export const downloadReservationRecords = (data: IBookingListQuery) => {
  let { start, to } = data;
  start=start.replace(/-/g, '')
  to=to.replace(/-/g, '')
  return http.get(
    `/api/v3/wineries/${UserModule.wineryId}/bookings/report?dateRange=${start}-${to}`,
    {
      responseType: "blob", //告诉服务器我们需要的响应格式
    }
  );
};

// 管理员新增预约
export const addReservation = (data: IBookingReqModel) => {
  return http.post(
    `/api/v3/wineries/${UserModule.wineryId}/bookings/offline`,
    data
  );
};

// 更新预约的到达状态
export const updateReservationArrivalStatus = (
  bookingId: string,
  arrived: boolean
) => {
  return http.patch(
    `/api/v3/wineries/${
      UserModule.wineryId
    }/bookings/${bookingId}/arrive?arrived=${arrived ? "yes" : "no"}`,
    {}
  );
};

// 更新预约信息
export const updateReservation = (
  bookingId: string,
  data: IBookingReqModel
) => {
  // return http.put(
  //   `/api/v3/wineries/${UserModule.wineryId}/bookings/${bookingId}`,
  //   data
  // )

  // 仅更新店员备注
  return http.put(
    `/api/v3/wineries/${UserModule.wineryId}/bookings/${bookingId}/admin-remark`,
    { remark: data.adminRemark===null?"" :data.adminRemark}
  );
};

// 检查是否有待确认的预约
export const checkUpdates = (within: number = 60) => {
  return http.get(
    `/api/v3/wineries/${UserModule.wineryId}/bookings/check-new-pending-bookings?within=${within}`
  );
};

// 获取所有的不可预约的日期
export const getDisabledDates = () => {
  return http.get(
    `/api/v3/wineries/${UserModule.wineryId}/calendars/dates/unavailable-dates`
  );
};

// 操作历史
export const bookLogs = (bookingId: string) => {
  return http.get(
    `/api/v3/wineries/${UserModule.wineryId}/bookings/${bookingId}/logs`
  );
};

// 管理员新增预约
export const selectVochers = (bookingId:string,data: IBookingProductSelectionReqModel) => {
  return http.post(
    `/api/v3/wineries/${UserModule.wineryId}/bookings/${bookingId}/vouchers/selections`,
    data
  );
};