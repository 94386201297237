






import { Component, Vue } from "vue-property-decorator";
import { UserModule } from "@/store/modules/user";
import router from "@/router";
import { PermissionModule } from "@/store/modules/permission";
import { constantRoutes } from "@/router";

@Component({
  name: "app",
})
export default class extends Vue {
  created() {
    // 注册一个全局自定义指令 去掉input前面的空格
    Vue.directive("filterSpecialChar", {
      update: function (el: any, { value, modifiers }, vnode) {
        try {
          //此处可以debug看看el具体值是什么,这里演示的是原生控件input,如果是使用element中的<el-input />标签,需要通过 el.children[0] 拿到原生input.
          if (!el.children[0]) {
            return false;
          }
          el.children[0].value = el.children[0].value.trimStart();
          el.children[0].dispatchEvent(
            new Event(modifiers.lazy ? "change" : "input")
          );
        } catch (e) {
          // console.log(e);
        }
      },
    });
    // UserModule.INIT_USERINFO()
    // UserModule.INIT_COMMONDATA()
  }
}
