



import { UserModule } from "@/store/modules/user";
// import router from "@/router";
import { whoami } from "@/remote/user";
// import { setToken } from "@/utils/storage";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
// 装饰器模式
@Component({
  components: {},
})
export default class extends Vue {
  beforeMount() {
    // console.log("beforeMount");
    var query = window.location.href.split("?")[1];
    // console.log(query);
    if (query) {
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == "token") {
          UserModule.SET_TOKEN_NEW(pair[1]);
          // setToken(`Bearer ${pair[1]}`)
          // console.log("before whoami");
          whoami()
            .then((res: any) => {
              if (res && res.data && res.data.data) {
                this.$message.success("登录成功");
                UserModule.RECORD_USERINFO(res.data.data);
                // UserModule.FetchVipLevels();
                // this.$router.push("/").catch((err) => {
                //   console.log(err);
                //   console.log("error");
                // });
                this.$router.push('/')
              }
            })
            .catch((e) => {
              // console.log(e);
            });
        }
      }
    }
  }
}
