import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import { RouteConfig } from 'vue-router'
import store from '@/store'
import { constantRoutes, asyncRoutes } from '@/router'
import { IBarsModel,IRolesModel } from '@/data/commonModel'

export const hasPermission = (roles: IBarsModel, route: RouteConfig) => {
 
  if (route.meta && route.meta.roles) {
    return roles.roles.some((role) =>{
      return route.meta&& route.meta.roles.includes(role.label)
    })
  } else {
    return false
  }
}

export const filterAsyncRoutes = (
  routes: RouteConfig[],
  roles: IBarsModel
) => {
  const res: RouteConfig[] = []

  routes.forEach((route) => {
    const r = { ...route }
    if (hasPermission(roles, r)) {
      if (r.children) {
        r.children = filterAsyncRoutes(r.children, roles)
      }
      res.push(r)
    }
  })
  return res
}

export interface IPermissionState {
  routes: RouteConfig[]
  dynamicRoutes: RouteConfig[]
}

@Module({ dynamic: true, store, name: 'permission' })
class Permission extends VuexModule implements IPermissionState {
  public routes: RouteConfig[] = []
  public dynamicRoutes: RouteConfig[] = []

  @Mutation
  public SET_ROUTES(routes: RouteConfig[]) {
    this.routes = constantRoutes.concat(routes)
    this.dynamicRoutes = routes
  }

  @Mutation
  public RESET_ROUTES() {
    this.routes = []
    this.dynamicRoutes = []
  }

  @Action
  public GenerateRoutes(roles: IBarsModel) {
    const accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
    this.SET_ROUTES(accessedRoutes)
  }
}

export const PermissionModule = getModule(Permission)
