import router, { resetRouter } from "@/router";
import NProgress from "nprogress"; // Progress 进度条
import "nprogress/nprogress.css"; // Progress 进度条样式
import { Message } from "element-ui";
import { PermissionModule } from "@/store/modules/permission";
import { BookingTimerModule } from "@/store/modules/bookingTimer";
import { UserModule } from "@/store/modules/user";
import { Route } from "vue-router";
import { IRoleModel, IBarsModel } from "./data/commonModel";

const whiteList = ["/login", "/login-okta"]; // 不重定向白名单
router.beforeEach(async (to: Route, _: Route, next: any) => {
  NProgress.start();
  // console.log(to.matched)
  // console.log(to)
  // console.log(PermissionModule.dynamicRoutes.length);
  if (to.matched.length === 0 && PermissionModule.dynamicRoutes.length > 0) {
    next("/401");
    NProgress.done();
    return;
  }

  if (UserModule.accessToken) {
    if (to.path === "/login") {
      if ("development" === process.env.NODE_ENV) {
        next();
      } else {
        window.location.replace(
          process.env.VUE_APP_BASE_API + "/api/v3/admins/login/okta"
        );
        next({ path: "/" });
      }
      NProgress.done();
    } else {
      // Check whether the user has obtained his permission roles
      if (PermissionModule.dynamicRoutes.length === 0) {
        try {
          // 切换账号时 重置路由
          resetRouter();
          // Note: roles must be a object array! such as: ['admin'] or ['developer', 'editor']
          UserModule.INIT_USERINFO();
          // UserModule.INIT_COMMONDATA()
          const roles = UserModule.wineries;
          // Generate accessible routes map based on role
          PermissionModule.GenerateRoutes(roles[0]);
          // Dynamically add accessible routes
          // debugger
          router.addRoutes(PermissionModule.dynamicRoutes);
          // Hack: ensure addRoutes is complete
          // Set the replace: true, so the navigation will not leave a history record
          // next({ path: fromPath })
          const roleLabels: string[] = [];
          roles[0].roles.forEach((element: IRoleModel) => {
            roleLabels.push(element.label);
          });
          if (
            (to.path === "/" || to.path === "/bookingList") &&
            !roleLabels.includes("Admin") &&
            !roleLabels.includes("Operation Team") &&
            !roleLabels.includes("Finance Team")
          ) {
            // console.log('应该到了这里')
            if (roleLabels.includes("E-Commerce Team")) {
              next("/productList");
            } else if (roleLabels.includes("Content Agency")) {
              next("/mainBannerList");
            } else if (roleLabels.includes("Coupon Manage")) {
              next("/couponData");
            }else if (roleLabels.includes("Audit Team")) {
              next("/List");
            }
          } else {
            // BookingTimerModule.START()
            next({ ...to, replace: true });
          }
        } catch (err) {
          // Remove token and redirect to login page
          UserModule.RESET_TOKEN();
          Message.error("Permission Has Error");
          // next(`/login?redirect=${to.path}`)
          NProgress.done();
        }
      } else {
        next();
      }
    }
  } else {
    // console.log(whiteList)
    if (whiteList.indexOf(to.path) !== -1) {
      // console.log("next-----")
      next();
    } else {
      // console.log("done-----")
      // console.log(process.env.NODE_ENV)
      if ("development" === process.env.NODE_ENV) {
        next("/login");
      } else {
        window.location.replace(
          process.env.VUE_APP_BASE_API + "/api/v3/admins/login/okta"
        );
      }
      NProgress.done();
    }
  }
  // next()
  NProgress.done();
});

router.afterEach(() => {
  NProgress.done(); // 结束Progress
});
