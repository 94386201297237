import Vue from 'vue'
import Router, { RouteConfig, RawLocation } from 'vue-router'
import LoginCallback from '@/router/LoginCallback.vue'
import Layout from '@/layout/Layout.vue'

Vue.use(Router)

export const constantRoutes: RouteConfig[] = [
  {
    name: 'login',
    path: '/login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/pages/site/Login.vue'),
    meta: { hidden: true },
  },
  {
    name: 'login-okta',
    path: '/login-okta',
    component: LoginCallback,
    meta: { hidden: true },
  },
  {
    name: '404',
    path: '/404',
    component: () =>
      import(/* webpackChunkName: "404" */ '@/pages/site/404.vue'),
    meta: { hidden: true, title: '404' },
  },
  {
    name: '401',
    path: '/401',
    component: () =>
      import(/* webpackChunkName: "401" */ '@/pages/site/401.vue'),
    meta: { hidden: true, title: '401' },
  },
]

export const asyncRoutes: RouteConfig[] = [
  {
    path: '/',
    name: 'dashboard',
    component: Layout,
    meta: { roles: ['Admin', 'Operation Team', 'Finance Team'] },
    redirect: '/bookingList', // if do not set roles, means: this page does not require permission
    children: [
      {
        path: '/bookingList',
        name: 'bookingList',
        component: () =>
          import(
            /* webpackChunkName "clientData" */ '@/pages/booking/BookingList.vue'
          ),
        meta: {
          title: '预约管理',
          requiresAuth: true,
          icon: 'el-icon-s-claim',
          roles: ['Admin', 'Operation Team', 'Finance Team'],
        },
      },
    ],
  },
  {
    path: '/seat-data',
    component: Layout,
    redirect: '/seat-data',
    meta: { roles: ['Admin', 'Operation Team'] },
    children: [
      {
        path: '/seat-data',
        name: 'SeatData',
        component: () =>
          import(/* webpackChunkName "seatData" */ '@/pages/seat/SeatData.vue'),
        meta: {
          roles: ['Admin', 'Operation Team'],
          requiresAuth: true,
          title: '日常席位管理',
          icon: 'el-icon-notebook-1',
        },
      },
    ],
  },
  {
    path: '/bannerList',
    component: Layout,
    redirect: '/bannerList',
    meta: {
      roles: ['Admin', 'Content Agency'],
      requiresAuth: true,
      title: 'Banner管理',
      icon: 'el-icon-folder',
    },
    children: [
      {
        path: '/mainBannerList',
        name: 'MainBannerList',
        component: () =>
          import(
            '@/pages/banner/MainBannerList.vue'
          ),
        meta: {
          roles: ['Admin', 'Content Agency'],
          title: '首页主Banner',
          icon: 'el-icon-notebook-2',
        },
      },
      {
        path: '/viceBannerList',
        name: 'ViceBannerList',
        component: () =>
          import(
            '@/pages/banner/ViceBannerList.vue'
          ),
        meta: {
          roles: ['Admin', 'Content Agency'],
          title: '首页副Banner',
          icon: 'el-icon-postcard',
        },
      },
    ],
  },
  {
    meta: { roles: ['Admin', 'Content Agency'] },
    path: '/wineryList',
    component: Layout,
    redirect: '/wineryList',
    children: [
      {
        path: '/wineryList',
        name: 'wineryList',
        component: () =>
          import(
            /* webpackChunkName "wineryList" */ '@/pages/winery/WineryList.vue'
          ),
        meta: {
          roles: ['Admin', 'Content Agency'],
          title: '酒庄介绍页管理',
          icon: 'el-icon-office-building',
        },
      },
    ],
  },
  {
    meta: { roles: ['Admin', 'Content Agency'] },
    path: '/packageList',
    component: Layout,
    redirect: '/packageList',
    children: [
      {
        path: '/packageList',
        name: 'packageList',
        component: () =>
          import(
            /* webpackChunkName "packageList" */ '@/pages/package/PackageList.vue'
          ),
        meta: {
          roles: ['Admin', 'Content Agency'],
          title: '套餐介绍页管理',
          icon: 'el-icon-present',
        },
      },
    ],
  },
  {
    meta: { roles: ['Admin', 'Content Agency'] },
    path: '/videoList',
    component: Layout,
    redirect: '/videoList',
    children: [
      {
        path: '/videoList',
        name: 'VideoList',
        component: () =>
          import(
            /* webpackChunkName "videoList" */ '@/pages/video/VideoList.vue'
          ),
        meta: {
          roles: ['Admin', 'Content Agency'],
          title: '开屏媒体管理',
          icon: 'el-icon-video-camera',
        },
      },
    ],
  },
  {
    meta: { roles: ['Admin', 'Content Agency'] },
    path: '/wineryAfterNoonTea',
    component: Layout,
    redirect: '/wineryAfterNoonTea',
    children: [
      {
        path: '/wineryAfterNoonTea',
        name: 'wineryAfterNoonTea',
        component: () =>
          import(
            /* webpackChunkName "wineryList" */ '@/pages/winery-afternoon-tea/WineryList.vue'
          ),
        meta: {
          roles: ['Admin', 'Content Agency'],
          title: '酒庄专区管理',
          icon: 'el-icon-office-building',
        },
      },
    ],
  },
  {
    meta: { roles: ['Admin', 'Audit Team'] },
    path: '/List',
    component: Layout,
    redirect: '/List',
    children: [
      {
        path: '/List',
        name: 'OperationLog',
        component: () =>
          import(
            /* webpackChunkName "wineryList" */ '@/pages/OperationLog/List.vue'
          ),
        meta: {
          roles: ['Admin', 'Audit Team'],
          title: '操作日志',
          icon: 'el-icon-tickets',
        },
      },
    ],
  },
  {
    meta: { roles: ['Admin'] },
    path: '/PopUpList',
    component: Layout,
    redirect: '/PopUpList',
    children: [
      {
        path: '/PopUpList',
        name: 'PopUpList',
        component: () =>
          import(
            '@/pages/store/StoreList.vue'
          ),
        meta: {
          roles: ['Admin'],
          title: '快闪店管理',
          icon: 'el-icon-tickets',
        },
      },
    ],
  },
  {
    meta: { roles: ['Admin'] },
    path: '/StoreList',
    component: Layout,
    redirect: '/StoreList',
    children: [
      {
        path: '/StoreList',
        name: 'StoreList',
        component: () =>
          import(
            '@/pages/store/StoreList.vue'
          ),
        meta: {
          roles: ['Admin'],
          title: '合作餐吧管理',
          icon: 'el-icon-tickets',
        },
      },
    ],
  },
  {
    meta: { roles: ['Admin'] },
    path: '/CouponList',
    component: Layout,
    redirect: '/CouponList',
    children: [
      {
        path: '/CouponList',
        name: 'CouponList',
        component: () =>
          import(
            /* webpackChunkName "wineryList" */ '@/pages/coupon/CouponList.vue'
          ),
        meta: {
          roles: ['Admin'],
          title: '礼券管理',
          icon: 'el-icon-tickets',
        },
      },
    ],
  },
  {
    meta: { roles: ['Admin','Content Agency'] },
    path: '/MediaStoreList',
    component: Layout,
    redirect: '/MediaStoreList',
    children: [
      {
        path: '/MediaStoreList',
        name: 'CouponLMediaStoreListist',
        component: () =>
          import(
            /* webpackChunkName "wineryList" */ '@/pages/media-store/MediaStoreList.vue'
          ),
        meta: {
          roles: ['Admin'],
          title: '视频管理',
          icon: 'el-icon-tickets',
        },
      },
    ],
  }
]
// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location:) {
//   return originalPush.call(this, location).catch(err => err)
// }

const createRouter = () =>
  new Router({
    // scrollBehavior功能只在 HTML5 history 模式下可用，当切换到新路由时，想要页面滚到顶部，或者是保持原先的滚动位置，就像重新加载页面那样
    // mode: 'history',
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) {
        // 当且仅当 popstate 导航 (通过浏览器的 前进/后退 按钮触发) 时才可用
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    },
    // mode: 'history',
    base: process.env.BASE_URL,
    routes: constantRoutes,
  })

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  // @ts-ignore
  router.matcher = newRouter.matcher // the relevant part
  // @ts-ignore
  // console.log(router.matcher)
}

export default router
