































import { Component, Vue } from "vue-property-decorator";
import { UserModule } from "@/store/modules/user";
import { PermissionModule } from "@/store/modules/permission";
import { BookingTimerModule } from "@/store/modules/bookingTimer";
import { logout } from "@/remote/user";
// 装饰器模式
@Component({
  components: {},
})
export default class LayoutHeader extends Vue {
  get userShowInfo() {
    return `${UserModule.email}/${UserModule.username}`;
  }

  onClickLogout() {
    this.logout();
  }

  onClickSwitchUser() {
    this.logout();
  }

  async logout() {
    console.log("logout-----");

    const token = UserModule.accessToken.substring(7);
    console.log(token);
    window.location.replace(
      process.env.VUE_APP_BASE_API +
        "/api/v3/admins/logout/okta?accessToken=" +
        token
    );
    PermissionModule.RESET_ROUTES();
    BookingTimerModule.CLEAR();
    BookingTimerModule.RESETFLAG();
    UserModule.CLEAR_USERINFO();
  }
}
