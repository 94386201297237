import Vue from 'vue'
import Vuex from 'vuex'
import { IUserState } from './modules/user'
import { ICocktailState } from './modules/cocktail'
import { IReservationState } from './modules/reservation'
import { IBookingTimerState } from './modules/bookingTimer'
import { IBannerState } from './modules/banner'

Vue.use(Vuex)

export interface IRootState {
  user: IUserState
  cocktail: ICocktailState
  reservation: IReservationState
  bookingTimer: IBookingTimerState,
  banner: IBannerState,
}

export default new Vuex.Store<IRootState>({})
