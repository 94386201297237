import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import { ElementTiptapPlugin } from 'element-tiptap'

import axios from './remote/interface'
import '@/permission'
import router from './router'
import store from './store'
import VCalendar from 'v-calendar' // 日历
import './class-component-hooks';

// 引入 ElementUI 样式
// import 'element-ui/lib/theme-chalk/index.css'
// 引入定制主题文件配置
import '@/assets/css/element-variables.scss'
import '@/assets/css/_index.scss'
// import element-tiptap 样式
// import 'element-tiptap/lib/index.css'

console.log('CMS Version:', process.env.CMS_VERSION)

Vue.use(VCalendar, {
  componentPrefix: 'vc'
})

Vue.prototype.$axios = axios

Vue.use(ElementUI)
// 安装 element-tiptap 插件
Vue.use(ElementTiptapPlugin, {
  /* 插件配置项 */
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
