import {
  VuexModule,
  Module,
  Mutation,
  getModule,
  Action
} from 'vuex-module-decorators'
import store from '@/store'
import { checkUpdates } from '@/remote/booking'
import { Notification } from 'element-ui'
import router from '@/router'

export interface IBookingTimerState {
  timer: number | undefined
  duration: number
  switchToComingSoonTab: boolean
}

@Module({ dynamic: true, store, name: 'bookingTimer' })
class BookingTimer extends VuexModule implements IBookingTimerState {
  public timer: number | undefined = undefined
  public duration = 60 * 1000
  public switchToComingSoonTab = false

  @Mutation
  public START() {
    clearInterval(this.timer)
    this.timer = setInterval(async () => {
      try {
        const res = await checkUpdates()
        // const res = {data:{"data":{"hasNew":true,"isSuccess":true,"message":"取消成功"},"resultCode":0,"resultMsg":"请求成功"}}
        if (res && res.data && res.data.data && res.data.data.hasNew) {
          const notification = Notification({
            title: '提示',
            message: '有新的预约待确认哦',
            duration: 0,
            onClick: () => {
              if (router.currentRoute.name === 'bookingList') {
                // 在预约管理页面，则切换tab
                this.switchToComingSoonTab = true
              } else {
                // 跳转到预约管理页面
                router.push({
                  name: 'bookingList',
                  query: { type: 'comingSoon' }
                })
              }
              // @ts-ignore
              Notification.closeAll()
            }
          })
        }
      } catch {
        // console.log('checkBookingUpdates 出错了')
      }
    }, this.duration)
  }

  @Mutation
  public CLEAR() {
    clearInterval(this.timer)
  }

  @Mutation
  public RESETFLAG() {
    this.switchToComingSoonTab = false
  }
}

export const BookingTimerModule = getModule(BookingTimer)
