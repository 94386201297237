import http from './interface'
import { ILoginReqModel } from '@/data/commonModel'
import { IOperationListQuery } from '@/pages/OperationLog/data/operationLog'
import { UserModule } from '@/store/modules/user'

export const postLogin = (data: ILoginReqModel) => {
  return http.post('/api/v3/admins/login', data)
}


export const getSkeksis = () => {
  return http.get('/api/v3/admins/test/skeksis')
}

export const whoami = () => {
  return http.get('/api/v3/admins/login/whoami')
}

export const logout = () => {
  return http.get('/api/v3/admins/logout/okta?accessToken='+UserModule.accessToken)
}
export const getOperationLogs = (data: IOperationListQuery) => {
  const { page, size, adminId, action, start, to } =
    data;
    let query=`pageSort=createdAt desc&pageIndex=${page-1}&pageSize=${size}`
    if(adminId){
      query+=('&adminId='+adminId)
    }
    if(action){
      query+=('&actions='+action)
    }
    if(start){
      query+=('&fromTime='+start)
    }
    if(to){
      query+=('&toTime='+to)
    }
  return http.get(
    `/api/v3/wineries/${UserModule.wineryId}/admin-action-log?${query}`
  );
};

export const getAdminsInfo=()=>{
  return http.get(
    `/api/v3/admins`
  );
}