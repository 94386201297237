import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import { Message } from 'element-ui'
import { UserModule } from '@/store/modules/user'
import { PermissionModule } from '@/store/modules/permission'
import router from '@/router'

export const CancelToken = axios.CancelToken
// import { ElBreadcrumbItem } from 'element-ui/types/breadcrumb-item';

/**
 *  http 请求类
 *
 *  请求拦截器，负责将客服端token的标识存储并放置在头部提交给服务器
 *
 *  拦截响应器 负责全局处理业务请求网络或者业务错误
 */

// 创建axios实例

const service = axios.create({
  baseURL: ('development' === process.env.NODE_ENV) ? '' : process.env.VUE_APP_BASE_API
})

// 请求拦截
service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
   console.log(config)
   if(config.method === "post"&&config.url?.startsWith("/api/v3/videos")){
    config.headers.contentType='multipart/form-data'
    console.log("set multipart")
   }
    if (UserModule.accessToken) {
      config.headers.Authorization = UserModule.accessToken
    }
    return config
  },
  (err: any) => {
    Promise.reject(err)
  }
)

// 响应拦截
service.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  (err: any) => {
    let errMsg = ''
    console.log("err",err.response)
    if (err && err.response.status) {
      switch (err.response.status) {
        case 400: {
          errMsg = err.response.data.resultMsg
          break
        }
        case 401: {
          errMsg = '登录状态失效，请重新登录'
          break
        }
        case 403: {
          errMsg = '访问受限'
          break
        }
        case 408: {
          errMsg = '请求超时'
          break
        }
        case 500: {
          errMsg = err.response.data.resultMsg
          break
        }
        case 501: {
          errMsg = '服务器未实现'
          break
        }
        case 502: {
          errMsg = '网关错误'
          break
        }
        case 503: {
          errMsg = '服务器不可用'
          break
        }
        case 504: {
          errMsg = '网关超时'
          break
        }
        case 505: {
          errMsg = 'http版本不支持'
          break
        }
        default: {
          const errorRes = err.response.data.error
          if (errorRes) {
            if (typeof errorRes === 'string') {
              errMsg = errorRes
            } else if (
              errorRes.message &&
              typeof errorRes.message === 'string'
            ) {
              errMsg = errorRes.message
            } else if (
              errorRes.error &&
              typeof errorRes.error.message === 'string'
            ) {
              errMsg = errorRes.error.message
            } else if (errorRes.error) {
              errMsg = err.response.data.error.error
            }
          }
          break
        }
      }
    } else {
      errMsg = err
    }
    Message.error(errMsg)
    return Promise.reject(errMsg)
  }
)

export default service
