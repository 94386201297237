












import { Component, Vue } from "vue-property-decorator";
import LayoutHeader from "./LayoutHeader.vue";
import Content from "./Content.vue";
import Sidebar from "./Sidebar.vue";

// 装饰器模式
@Component({
  components: {
    LayoutHeader,
    Content,
    Sidebar,
  },
})
export default class extends Vue {
  get key() {
    return this.$route.path;
  }
}
