import Cookies from 'js-cookie'

// User
const tokenKey = 'Admin-Token'
export const getToken = () => Cookies.get(tokenKey)
export const setToken = (token: string) => Cookies.set(tokenKey, token)
export const removeToken = () => Cookies.remove(tokenKey)

const userInfoKey = 'User-info'
export const getUserInfo = () => Cookies.get(userInfoKey)
export const setUserInfo = (userInfo: string) =>
  Cookies.set(userInfoKey, userInfo)
export const removeUserInfo = () => Cookies.remove(userInfoKey)

// VipLevel
// const vipLevelsKey = 'VIP-LEVELS'
// export const getLocalVipLevels = () => Cookies.get(vipLevelsKey)
// export const setVipLevels = (data: object) =>
//   Cookies.set(vipLevelsKey, JSON.stringify(data))
// export const removeVipLevels = () => Cookies.remove(vipLevelsKey)

// const productListKey = 'PRODUCT-LIST'
// export const getLocalProductList = () => Cookies.get(productListKey)
// export const setProductList = (data: object) =>
//   Cookies.set(productListKey, JSON.stringify(data))
// export const removeProductList = () => Cookies.remove(productListKey)

// const stautsListKey = 'STATUS-LIST'
// export const getStautsList = () => Cookies.get(stautsListKey)
// export const setStautsList = (data: object) =>
//   Cookies.set(stautsListKey, JSON.stringify(data))
// export const removeStautsList = () => Cookies.remove(stautsListKey)
