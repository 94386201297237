import http from './interface'
import { IEventListQuery } from '@/pages/event/data/eventModel'
import { IStoreModel ,IStoreSortReqModel} from '@/pages/store/data/storeModel'

// 创建 
export const createBar = (data: IStoreModel) => {
  return http.post(`/api/v3/wineries`, data)
}

export const getBars = () => {
  const url = `/api/v3/wineries/popup-store`
  return http.get(url)
}

// 更新 
export const updateBar = (wineryId: string, data: IStoreModel) => {
  // console.log("updateBar222")
  // console.log(wineryId)
  return http.put(`/api/v3/wineries/${wineryId}`, data)
}

// 删除
export const deleteBar = (wineryId: string) => {
  return http.delete(`/api/v3/wineries/${wineryId}`)
}
 
// 取消发布和发布
export const updateBarPublishStatus = (
  wineryId: string,
  isPublished: boolean
) => {
  return http.patch(
    `/api/v3/wineries/${wineryId}/publish`,
    {
      isPublished,
    }
  )
}


export const inActivityBars = () => {
  const url = `/api/v3/wineries/in-activity`
  return http.get(url)
}
// 更新展示顺序
export const updateStoreListSort = (list: IStoreSortReqModel[]) => {
  return http.put(`/api/v3/wineries/sort/change-order`, list)
}

