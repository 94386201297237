


















































































import { Component, Vue } from "vue-property-decorator";
import variables from "@/assets/css/element-variables.scss";
import { PermissionModule } from "@/store/modules/permission";
import { UserModule } from "@/store/modules/user";
import { BookingTimerModule } from "@/store/modules/bookingTimer";
import { RouteConfig } from "vue-router";
@Component({
  components: {},
})
export default class SideBar extends Vue {
  get options() {
    return UserModule.wineries;
  }
  value = UserModule.wineries[0].id;
  get routers() {
    return PermissionModule.routes;
  }
  get activeMenu() {
    const route = this.$route;
    const { meta, path } = route;
    // if set path, the sidebar will highlight the path you set
    if (meta && meta.activeMenu) {
      return meta.activeMenu;
    }
    return path;
  }
  get variables() {
    return variables;
  }

  // Methods
  getVisibleItemNumber(item: RouteConfig) {
    if (item.children && item.children.length > 1) {
      let visibleNumber = 0;
      item.children.forEach((element: RouteConfig) => {
        if (
          element &&
          element.meta &&
          (!element.meta.hidden || element.meta.hidden === undefined)
        ) {
          visibleNumber += 1;
        }
      });
      return visibleNumber;
    }
    return 0;
  }

  _onSelectChange(value: any) {
    // console.log(value);
    UserModule.SET_BAR_INDEX(value);
    //  PermissionModule.RESET_ROUTES()
    BookingTimerModule.CLEAR();
    BookingTimerModule.RESETFLAG();
    // this.$router.go(0)
    //  跳转到登录页的时候顺便刷新
    // this.$router.replace('/')
  }
}
